import React, { useState } from "react";
import { Title } from "../components/pages/Title";
import { Modal } from "../components/Modal";
import { useModal } from "../hooks/useModal";
import { useVistas } from "../hooks/useVistas";
import { usePersonas } from "../hooks/usePersonas";
import { useHistoriaClinica } from "../hooks/useHistoriasClinicas";
import { HeaderParameters } from "../components/pages/HeaderParameters";

export const HistoriaClinica = () => {
  const { enfermedades, categoriasNoPatologicos } = useVistas();
  const { personas } = usePersonas()
  const {  antecedente,
      historiaClinica,
      historiasclinicas,
      setHistoriaClinica,
      setHistoriasClinicas,
      listarHistoriasClinicas,
      editarValorAntecedente,
      editarValorHistoriaClinica,
      obtenerHistoriaClinica,
      guardarHistoriaClinica,
      guardarAntecedente} = useHistoriaClinica()


  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [isOpen, openModal, closeModal] = useModal(false);
  const [palabraBuscar, setPalabraBuscar] = useState("");

  const [antecedentesHeredoFamiliares, setAntecedentesHeredoFamiliares] = useState([]);
  const [antecedentesNoPatologicos, setAntecedentesNoPatologicos] = useState([]);
  const [antecedentesPatologicos, setAntecedentesPatologicos] = useState([]);

  const generarCodHistoriaClinica = () => {
    return `HC${Math.floor(Math.random() * 10000).toString().padStart(4, "0")}`;
  };

  const handleHistoriaClinicaChange = (key, value) => {
    setHistoriaClinica((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePacienteChange = (CodPaciente) => {
    const paciente = personas.find((p) => p.CodPersona === CodPaciente);
    setPacienteSeleccionado(paciente);
    setHistoriaClinica((prev) => ({
      ...prev,
      CodPaciente: CodPaciente,
      CodHistoriaClinica: generarCodHistoriaClinica(),
    }));
  };

  const agregarAntecedenteHeredoFamiliar = () => {
    setAntecedentesHeredoFamiliares([
      ...antecedentesHeredoFamiliares,
      { CodHistoriaClinica: historiaClinica.CodHistoriaClinica, Familiar: "", CodEnfermedad: "", Detalle: "" },
    ]);
  };

  const agregarAntecedenteNoPatologico = () => {
    setAntecedentesNoPatologicos([
      ...antecedentesNoPatologicos,
      { CodHistoriaClinica: historiaClinica.CodHistoriaClinica, CodCategoriaNoPatologico: "", Detalle: "" },
    ]);
  };

  const agregarAntecedentePatologico = () => {
    setAntecedentesPatologicos([
      ...antecedentesPatologicos,
      { CodHistoriaClinica: historiaClinica.CodHistoriaClinica, CodEnfermedad: "", Detalle: "" },
    ]);
  };

  const registrarHistoriaClinica = () => {
    setHistoriasClinicas([
      ...historiasclinicas,
      { ...historiaClinica, NombreCompleto: `${pacienteSeleccionado.Nombre} ${pacienteSeleccionado.ApellidoPaterno} ${pacienteSeleccionado.ApellidoMaterno}`, FechaCreacion: new Date().toISOString().split('T')[0] },
    ]);
    closeModal();
  };

  return (
    <div className="p-6">
      <Title title="Listado de Historias Clínicas" />
      <HeaderParameters
        openModal={() => openModal()}
        palabraBuscar={palabraBuscar}
        setPalabraBuscar={setPalabraBuscar}
        className="mt-4 mb-4"
      />
      <div className="max-w-4xl mx-auto bg-white shadow p-6 rounded-lg">
        <table className="w-full bg-white shadow rounded">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-4 text-left">Código Historia Clínica</th>
              <th className="p-4 text-left">Paciente</th>
              <th className="p-4 text-left">Fecha de Creación</th>
            </tr>
          </thead>
          <tbody>
            {historiasclinicas?.map((hc) => (
              <tr key={hc?.CodHistoriaClinica} className="border-b">
                <td className="p-4">{hc?.CodHistoriaClinica}</td>
                <td className="p-4">{hc?.NombreCompleto}</td>
                <td className="p-4">{hc?.FechaCreacion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isModalOpen={isOpen}
        closeModal={closeModal}
        title="Registrar Nueva Historia Clínica"
        actionLabel="Registrar"
        onAction={()=>closeModal()}
        modalWidth="w-3/4"
      >
        <div className="grid grid-cols-3 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Paciente
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={historiaClinica.CodPaciente}
              // onChange={(e) => handlePacienteChange(e.target.value)}
            >
              <option value="" disabled>
                Selecciona un paciente
              </option>
              {personas.map((paciente) => (
                <option key={paciente.CodPersona} value={paciente.CodPersona}>
                  {paciente.Nombre} {paciente.ApellidoPaterno} {paciente.ApellidoMaterno}
                </option>
              ))}
            </select>
          </div>

          {pacienteSeleccionado && (
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Nombre Completo</label>
                <input
                  className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={`${pacienteSeleccionado.Nombre} ${pacienteSeleccionado.ApellidoPaterno} ${pacienteSeleccionado.ApellidoMaterno}`}
                  disabled
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Fecha de Nacimiento</label>
                <input
                  className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={pacienteSeleccionado.FechaNacimiento}
                  disabled
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Edad</label>
                <input
                  className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={
                    new Date().getFullYear() -
                    new Date(pacienteSeleccionado.FechaNacimiento).getFullYear()
                  }
                  disabled
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Antecedentes Heredofamiliares</h3>
          <table className="w-full bg-white shadow rounded">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 text-left">Familiar</th>
                <th className="p-2 text-left">Enfermedad</th>
                <th className="p-2 text-left">Detalle</th>
                <th className="p-2 text-right">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {antecedentesHeredoFamiliares.map((antecedente, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.Familiar}
                      onChange={(e) => {
                        const updated = [...antecedentesHeredoFamiliares];
                        updated[index].Familiar = e.target.value;
                        setAntecedentesHeredoFamiliares(updated);
                      }}
                    />
                  </td>
                  <td className="p-2">
                    <select
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.CodEnfermedad}
                      onChange={(e) => {
                        const updated = [...antecedentesHeredoFamiliares];
                        updated[index].CodEnfermedad = e.target.value;
                        setAntecedentesHeredoFamiliares(updated);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una enfermedad
                      </option>
                      {enfermedades.map((enf) => (
                        <option key={enf.CodEnfermedad} value={enf.CodEnfermedad}>
                          {enf.NombreEnfermedad}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.Detalle}
                      onChange={(e) => {
                        const updated = [...antecedentesHeredoFamiliares];
                        updated[index].Detalle = e.target.value;
                        setAntecedentesHeredoFamiliares(updated);
                      }}
                    />
                  </td>
                  <td className="p-2 text-right">
                    <button
                      onClick={() => {
                        const updated = antecedentesHeredoFamiliares.filter((_, i) => i !== index);
                        setAntecedentesHeredoFamiliares(updated);
                      }}
                      className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" className="p-2 text-center">
                  <button
                    onClick={agregarAntecedenteHeredoFamiliar}
                    className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Agregar Antecedente
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Antecedentes Personales No Patológicos</h3>
          <table className="w-full bg-white shadow rounded">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 text-left">Categoría No Patológica</th>
                <th className="p-2 text-left">Detalle</th>
                <th className="p-2 text-right">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {antecedentesNoPatologicos.map((antecedente, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">
                    <select
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.CodCategoriaNoPatologico}
                      onChange={(e) => {
                        const updated = [...antecedentesNoPatologicos];
                        updated[index].CodCategoriaNoPatologico = e.target.value;
                        setAntecedentesNoPatologicos(updated);
                      }}
                    >
                      <option value="" disabled>
                        Seleccione
                      </option>
                      {categoriasNoPatologicos.map((cat) => (
                        <option key={cat.CodCategoriaNoPatologico} value={cat.CodCategoriaNoPatologico}>
                          {cat.NombreCategoriaNoPatologico}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.Detalle}
                      onChange={(e) => {
                        const updated = [...antecedentesNoPatologicos];
                        updated[index].Detalle = e.target.value;
                        setAntecedentesNoPatologicos(updated);
                      }}
                    />
                  </td>
                  <td className="p-2 text-right">
                    <button
                      onClick={() => {
                        const updated = antecedentesNoPatologicos.filter((_, i) => i !== index);
                        setAntecedentesNoPatologicos(updated);
                      }}
                      className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" className="p-2 text-center">
                  <button
                    onClick={agregarAntecedenteNoPatologico}
                    className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Agregar Antecedente
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Antecedentes Personales Patológicos</h3>
          <table className="w-full bg-white shadow rounded">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 text-left">Enfermedad</th>
                <th className="p-2 text-left">Detalle</th>
                <th className="p-2 text-right">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {antecedentesPatologicos.map((antecedente, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">
                    <select
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.CodEnfermedad}
                      onChange={(e) => {
                        const updated = [...antecedentesPatologicos];
                        updated[index].CodEnfermedad = e.target.value;
                        setAntecedentesPatologicos(updated);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una enfermedad
                      </option>
                      {enfermedades.map((enf) => (
                        <option key={enf.CodEnfermedad} value={enf.CodEnfermedad}>
                          {enf.NombreEnfermedad}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded"
                      value={antecedente.Detalle}
                      onChange={(e) => {
                        const updated = [...antecedentesPatologicos];
                        updated[index].Detalle = e.target.value;
                        setAntecedentesPatologicos(updated);
                      }}
                    />
                  </td>
                  <td className="p-2 text-right">
                    <button
                      onClick={() => {
                        const updated = antecedentesPatologicos.filter((_, i) => i !== index);
                        setAntecedentesPatologicos(updated);
                      }}
                      className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" className="p-2 text-center">
                  <button
                    onClick={agregarAntecedentePatologico}
                    className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Agregar Antecedente
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};
