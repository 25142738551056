import React from 'react';

export const Modal = ({ 
  isModalOpen, 
  closeModal, 
  title, 
  children, 
  actionLabel, 
  onAction 
}) => {
  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-[50%] h-[80%] max-h-[80%] overflow-auto bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">{title}</h2>
            {children}
            <div className="flex justify-end mt-4">
              <button
                onClick={onAction}
                className="p-2 bg-green-500 text-white rounded hover:bg-green-600 mr-2"
              >
                {actionLabel}
              </button>
              <button
                onClick={closeModal}
                className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
