import React from 'react';

export const RegisterButton = ({ openModal }) => {
  return (
    <button
      onClick={openModal}
      className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
    >
      Registrar
    </button>
  );
};
