import React, { useState, useEffect } from 'react';
import '../assets/css/slider.css';
import banner1 from '../assets/images/banner1.jpg';
import banner2 from '../assets/images/banner2.jpg';
import banner3 from '../assets/images/banner3.jpg';

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [banner1, banner2, banner3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const slide = (direction) => {
    if (direction === 'next') {
      setCurrentIndex((currentIndex + 1) % slides.length);
    } else if (direction === 'prev') {
      setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
    }
  };

  return (
    <div className="slider-container">
      <div className="slides">
        {slides.map((slide, index) => (
          <div
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            key={index}
          >
            <img src={slide} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="slider-controls">
        <button className="prev" onClick={() => slide('prev')}>&#10094;</button>
        <button className="next" onClick={() => slide('next')}>&#10095;</button>
      </div>
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
