import { useState, useEffect, useContext } from "react";
// import { notify, formatDate, obtenerFechaHora, parseFechaHoraDate } from "../../utils/utils";
// import { Fetch } from "../../services/api";
// import { useModal } from '../useModal';
import { Fetch } from "../services/api";
import { notify } from "../utils/utils";


export const usePersonas = () => {
    const fecha = new Date()
    const hora = fecha.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

    const personaDefault = {
      CodPersona:0,
      Nombres: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      FechaNacimiento: "",
      Direccion: "",
      Genero: "",
      Celular: "",
      CodTipoDocumento: 0,
      NumeroDocumento: ""
    }
  
  useEffect(() => { 
    listarPersonas()
  },[])
  
  const [persona, setPersona] = useState(personaDefault)
  const [personas, setPersonas ] = useState([])
    
  const listarPersonas = async () => {
    notify('Usuario o contraseña incorrectos', 'error')    
    const response = await Fetch({
        url: 'listar-personas'
    });

    console.log(response.content)
    if (response.isValid){
      setPersonas(response.content)
      // openModal()
    }else{
        console.log(response.content)
    }
    }

    const guardarPersona = async () => {
        const esGuardar = persona.CodPersona
        const response = await Fetch({
            url: 'registrar-persona',
            method:'POST', 
            body: JSON.stringify({
                Nombres: persona.Nombres,
                ApellidoPaterno: persona.ApellidoPaterno,
                ApellidoMaterno:persona.ApellidoMaterno,
                FechaNacimiento: persona.FechaNacimiento  ,
                Direccion: persona.Direccion,
                Genero: persona.Genero,
                Celular: persona.Celular,
                CodTipoDocumento: persona.CodTipoDocumento,
                NumeroDocumento: persona.NumeroDocumento,
            })

        });
        notify(response.content, (response.isValid ? 'success' : 'error'));
        if (response.isValid){
            await listarPersonas()
        }
    }  

  const editarValorPersona = (key, value) => {
      console.log("editarValorPersona->",key,value)
        setPersona(persona => {
            return {
                ...persona,
                [key]: value
            }
        });
    }

    const obtenerPersona = async (cod_persona) => {
        
      const response = await Fetch({
        url: `datos-persona/${cod_persona}`
      })
            
      if (response.isValid) {
        setPersona(response.content)
      }
    }


    return{
      persona,
      setPersona,
      personas,
      setPersonas,
      listarPersonas,
      editarValorPersona,
      obtenerPersona,
      guardarPersona
      
    }
}