import React from 'react';

export const SearchBar = ({ palabraBuscar, setPalabraBuscar }) => {
  return (
    <div className="relative flex items-center">
      <i className="fas fa-search absolute left-3 text-gray-400"></i>
      <input
        type="text"
        placeholder="Buscar..."
        value={palabraBuscar}
        onChange={(e) => setPalabraBuscar(e.target.value)}
        className="p-2 pl-10 rounded border border-gray-300 w-full"
      />
    </div>
  );
};
