import React from 'react';
import { RegisterButton } from './RegisterButton';
import { SearchBar } from './SearchBar';

export const HeaderParameters = ({ openModal, palabraBuscar, setPalabraBuscar, className }) => {
  return (
    <div className={`flex justify-between mb-4 items-center ${className}`}>
      <RegisterButton openModal={openModal} />
      <SearchBar palabraBuscar={palabraBuscar} setPalabraBuscar={setPalabraBuscar} />
    </div>
  );
};
