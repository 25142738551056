import { useState, useEffect, useContext } from "react";
import { Fetch } from "../services/api";
import { notify } from "../utils/utils";


export const useHistoriaClinica = () => {
    const fecha = new Date()
    const hora = fecha.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

    const historiaClinicaDefault = {
      CodHistoriaClinica:0,
      FechaRegistro: "",
      CodMedico: "",
    }
  

  const antecedenteDefault = {
    TipoAntecedente: "",
    CodHistoriaClinica: 0,
    CodEnfermedad: 0,
    Detalle: "",
    Parentesco: "",
    CodCategoriaNoPatologico: 0
  }
  
  useEffect(() => { 
    listarHistoriasClinicas()
  },[])
  
  const [historiaClinica, setHistoriaClinica] = useState(historiaClinicaDefault)
  const [historiasClinicas, setHistoriasClinicas ] = useState([])
  const [antecedente, setAntecedente ] = useState(antecedenteDefault)

  const listarHistoriasClinicas = async () => {
    notify('Usuario o contraseña incorrectos', 'error')    
    const response = await Fetch({
        url: 'listar-historias-clinicas'
    });

    console.log(response.content)
    if (response.isValid){
      setHistoriasClinicas(response.content)
    }else{
      console.log(response.content)
    }
    }

    const guardarHistoriaClinica = async () => {
        const esGuardar = historiaClinicaDefault.CodHistoria
        const response = await Fetch({
            url: 'registrar-historia-clinica',
            method:'POST', 
            body: JSON.stringify({
                FechaRegistro: historiaClinica.FechaRegistro,
                CodMedico: historiaClinica.CodMedico,
            })

        });
        notify(response.content, (response.isValid ? 'success' : 'error'));
        if (response.isValid){
            await listarHistoriasClinicas()
        }
  }  
  const guardarAntecedente = async () => {
      // const esGuardar = antecedente.CodAntecedente
      const response = await Fetch({
          url: 'registrar-antecedente',
          method:'POST', 
        body: JSON.stringify({
            TipoAntecedente: antecedente.TipoAntecedente,
            CodHistoriaClinica:antecedente.CodHistoriaClinica,
            CodEnfermedad: antecedente.CodEnfermedad,
            Detalle: antecedente.Detalle,
            Parentesco: antecedente.Parentesco,
            CodCategoriaNoPatologico:antecedente.CodCategoriaNoPatologico,
          })

      });
      notify(response.content, (response.isValid ? 'success' : 'error'));
      if (response.isValid){
          await listarHistoriasClinicas()
      }
  }  


  const editarValorHistoriaClinica = (key, value) => {
      console.log("editarValorHistoriaClinica->",key,value)
        setHistoriaClinica(historiaClinica => {
            return {
                ...historiaClinica,
                [key]: value
            }
        });
  }
  
  const editarValorAntecedente = (key, value) => {
      console.log("editarValorAntecedente->",key,value)
        setAntecedente(antecedente => {
            return {
                ...antecedente,
                [key]: value
            }
        });
    }


    
  const obtenerHistoriaClinica = async (cod_historia_clinica) => {
    const response = await Fetch({
      url: `datos-persona/${cod_historia_clinica}`
    })
          
    if (response.isValid) {
      setHistoriaClinica(response.content)
    }
  }


    return{
      antecedente,
      historiaClinica,
      historiasClinicas,
      setHistoriaClinica,
      setHistoriasClinicas,
      listarHistoriasClinicas,
      editarValorAntecedente,
      editarValorHistoriaClinica,
      obtenerHistoriaClinica,
      guardarHistoriaClinica,
      guardarAntecedente
    }
}