import { useState, useEffect } from "react";
// import { notify, } from "../../utils/utils";
import { Fetch } from "../services/api";


export const useVistas = () => {
    const [ tiposDocumentos, setTiposDocumentos ] = useState([]) 
    const [ especialidades, setEspecialidades ] = useState([]) 
    const [ horarios, setHorarios ] = useState([])
    const [ enfermedades, setEnfermedades ] = useState([]) 
    const [ consultorios, setConsultorios ] = useState([]) 
    const [ categoriasNoPatologicos, setCategoriasNoPatologicos ] = useState([])
    
    useEffect(()=>{
      listarTiposDocumentos()
      listarEspecialidades()
      listarHorarios()
      listarEnfermedades()
      listarConsultorios()
      listarNoPatologico()
    },[])

    const listarTiposDocumentos = async () => {
        const response = await Fetch({
            url: 'tipos-documento'
        });
        // console.log("RESPONSE LISTAR TIPOS DOCUMENTOS -> ", response.content)

        if (response.isValid){
            setTiposDocumentos(response.content);
            // openModal()
        }else{
            console.log("error--------->",response.content)
        }
    }


    const listarEspecialidades = async () => {
        const response = await Fetch({
            url: 'especialidades'
        });
        console.log("RESPONSE listarEspecialidades -> ", response.content)

        if (response.isValid){
            setEspecialidades(response.content);
            // openModal()
        }else{
            console.log(response.content)
        }
    }

    const listarHorarios = async () => {
        const response = await Fetch({
            url: 'horarios'
        });
        console.log("RESPONSE listarHorarios -> ", response.content)

        if (response.isValid){
            setHorarios(response.content);
            // openModal()
        }else{
            console.log(response.content)
        }
    }


    const listarEnfermedades = async () => {
        const response = await Fetch({
            url: 'listar-enfermedades'
        });
        console.log("RESPONSE listarEnfermedades -> ", response.content)

        if (response.isValid){
            setEnfermedades(response.content);
            // openModal()
        }else{
            console.log(response.content)
        }
    }

    const listarConsultorios = async () => {
        const response = await Fetch({
            url: 'consultorios'
        });
        console.log("RESPONSE listarConsultorios -> ", response.content)

        if (response.isValid){
            setConsultorios(response.content);
            // openModal()
        }else{
            console.log(response.content)
        }
  }
  
    const listarNoPatologico = async () => {
        const response = await Fetch({
            url: 'listar-categorias-no-patologicas'
        });
        console.log("RESPONSE listar-categorias-no-patologicas -> ", response.content)

        if (response.isValid){
            setCategoriasNoPatologicos(response.content);
            // openModal()
        }else{
            console.log(response.content)
        }
    }

    

    return{
      tiposDocumentos,
      especialidades,
      horarios,
      enfermedades,
      consultorios,
      listarTiposDocumentos,
      listarEspecialidades,
      listarHorarios,
      listarEnfermedades,
      listarConsultorios,
      listarNoPatologico,
      categoriasNoPatologicos

    }
}