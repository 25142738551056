import React, { useState } from "react";
import logo from '../assets/images/logoMedimanagerFinal.fw.png'

export const Home = () => {
 
  return (
    <div className="flex text-center justify-center align-center item-center mt-[8%]">
      <img className='w-[30%] opacity-25' src={logo} />
    </div>
  );
};
