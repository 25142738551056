import React from 'react';
import logo from '../../assets/images/logoMedimanagerFinal4.fw.png'
export const Header = ({ toggleMenu }) => {
  return (
     <header className="header">
      <div className="header-left">
        <img className='w-[50px] mr-[30px]' src={logo} />
        <button className=" menu-toggle" onClick={toggleMenu}>☰</button>
      </div>
      <div className="header-right">
        <div className="icon alert-icon">🔔</div>
        <div className="user-profile">
          <img src={'https://icons.veryicon.com/png/o/miscellaneous/user-avatar/user-avatar-male-5.png'} alt="Usuario" className="user-photo border border-gray-300" />
          <span className="user-name">Admin</span>
        </div>
      </div>
    </header>
  );
};
