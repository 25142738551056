export const Fetch = async ({
    url,
    method,
    headers,
    body,
    typeResponse
}) => {

    const urlBase = 'https://medimanager-webapi.henrycerna.com/' 
    // const urlBase = 'http://192.168.0.115:8000/' 

    const headersDefault = headers || {
        'Content-Type': 'application/json',
    };

    const response = await fetch(urlBase + url, {
        method: method || 'GET',
        headers: {
            ...headersDefault,
        },
        body: body || null
    });

    if ([401].includes(Number(response.status))) {
        localStorage.clear();
        window.location.reload(false);
    }

    return await response[typeResponse || 'json']()
}





