import React, { useState } from "react";
import { Title } from "../components/pages/Title";
import { Modal } from "../components/Modal";
import { useModal } from "../hooks/useModal";
import { useVistas } from "../hooks/useVistas";
import { HeaderParameters } from "../components/pages/HeaderParameters";

export const Citas = () => {
  const { tiposDocumentos, especialidades, horarios, enfermedades, consultorios } = useVistas();

  const ListaPacientes = [
    { CodPersona: "PAC001", Nombre: "Juan", ApellidoPaterno: "Pérez", ApellidoMaterno: "González", FechaNacimiento: "1985-01-01" },
    { CodPersona: "PAC002", Nombre: "María", ApellidoPaterno: "García", ApellidoMaterno: "López", FechaNacimiento: "1990-05-12" },
  ];

  const ListaDoctores = [
    { CodMedico: "DOC001", Nombre: "Dr. Juan", ApellidoPaterno: "Pérez", Especialidad: "Cardiología", NumeroColegiatura: "123456" },
    { CodMedico: "DOC002", Nombre: "Dra. María", ApellidoPaterno: "García", Especialidad: "Pediatría", NumeroColegiatura: "654321" },
  ];

  const [citas, setCitas] = useState([]);
  const [cita, setCita] = useState({ CodPaciente: "", CodMedico: "", Fecha: "", Hora: "", CodConsultorio: "", Comentario: "" });
  const [doctorSeleccionado, setDoctorSeleccionado] = useState(null);
  const [isOpen, openModal, closeModal] = useModal(false);

  const handleCitaChange = (key, value) => {
    setCita((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDoctorChange = (CodMedico) => {
    const doctor = ListaDoctores.find((d) => d.CodMedico === CodMedico);
    setDoctorSeleccionado(doctor);
    handleCitaChange("CodMedico", CodMedico);
  };
  const [palabraBuscar, setPalabraBuscar] = useState("");

  const registrarCita = () => {
    setCitas([
      ...citas,
      { ...cita, Paciente: ListaPacientes.find((p) => p.CodPersona === cita.CodPaciente).Nombre },
    ]);
    closeModal();
  };

  return (
    <div className="p-6">
      <Title title="Gestión de Citas Médicas" />
      <HeaderParameters
        openModal={() => openModal()}
        palabraBuscar={palabraBuscar}
        setPalabraBuscar={setPalabraBuscar}
        className="mt-4 mb-4"
      />

      <div className="max-w-4xl mx-auto bg-white shadow p-6 rounded-lg">
        <table className="w-full bg-white shadow rounded">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-4 text-left">Paciente</th>
              <th className="p-4 text-left">Doctor</th>
              <th className="p-4 text-left">Fecha</th>
              <th className="p-4 text-left">Hora</th>
              <th className="p-4 text-left">Consultorio</th>
              <th className="p-4 text-right">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {citas.map((cita, index) => (
              <tr key={index} className="border-b">
                <td className="p-4">{cita.Paciente}</td>
                <td className="p-4">{doctorSeleccionado?.Nombre} {doctorSeleccionado?.ApellidoPaterno}</td>
                <td className="p-4">{cita.Fecha}</td>
                <td className="p-4">{cita.Hora}</td>
                <td className="p-4">{consultorios.find((con) => con.CodConsultorio === cita.CodConsultorio)?.NumeroConsultorio}</td>
                <td className="p-4 text-right">
                  <button
                    onClick={() => { openModal(); handleCitaChange("CodPaciente", cita.CodPaciente); handleDoctorChange(cita.CodMedico); }}
                    className="p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                  >
                    Reprogramar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isModalOpen={isOpen}
        closeModal={closeModal}
        title="Registrar Nueva Cita Médica"
        actionLabel="Registrar"
        onAction={registrarCita}
        modalWidth="w-3/4" l
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Paciente
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={cita.CodPaciente}
              onChange={(e) => handleCitaChange("CodPaciente", e.target.value)}
            >
              <option value="" disabled>
                Selecciona un paciente
              </option>
              {ListaPacientes.map((paciente) => (
                <option key={paciente.CodPersona} value={paciente.CodPersona}>
                  {paciente.Nombre} {paciente.ApellidoPaterno} {paciente.ApellidoMaterno}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Doctor
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={cita.CodMedico}
              onChange={(e) => handleDoctorChange(e.target.value)}
            >
              <option value="" disabled>
                Selecciona un doctor
              </option>
              {ListaDoctores.map((doctor) => (
                <option key={doctor.CodMedico} value={doctor.CodMedico}>
                  {doctor.Nombre} {doctor.ApellidoPaterno} - {doctor.Especialidad}
                </option>
              ))}
            </select>
          </div>

          {doctorSeleccionado && (
            <div className="col-span-2 mb-4 ">
              <h4 className="text-gray-700 font-semibold">Programación del Doctor</h4>
              <p>Especialidad: {doctorSeleccionado.Especialidad}</p>
              <p>Colegiatura: {doctorSeleccionado.NumeroColegiatura}</p>
              <div className="overflow-auto max-h-[300px]">
                <table className="w-full bg-white shadow rounded mt-2">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="p-2 text-left">Día</th>
                      <th className="p-2 text-left">Hora Inicio</th>
                      <th className="p-2 text-left">Hora Fin</th>
                      <th className="p-2 text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {horarios.map((horario, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">{horario.DiaSemana}</td>
                        <td className="p-2">{horario.HoraInicio}</td>
                        <td className="p-2">{horario.HoraFin}</td>
                        <td className="p-2">
                          <input type="checkbox"></input>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Consultorio
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={cita.CodConsultorio}
              onChange={(e) => handleCitaChange("CodConsultorio", e.target.value)}
            >
              <option value="" disabled>
                Selecciona un consultorio
              </option>
              {consultorios.map((consultorio) => (
                <option key={consultorio.CodConsultorio} value={consultorio.CodConsultorio}>
                  Consultorio {consultorio.NumeroConsultorio} - Piso {consultorio.Piso}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-2 mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Comentarios
            </label>
            <textarea
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={cita.Comentario}
              onChange={(e) => handleCitaChange("Comentario", e.target.value)}
            ></textarea>
          </div>
        </div>
        
      </Modal>
    </div>
  );
};
