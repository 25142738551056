import React, { useState, useEffect } from "react";
import { Title } from "../components/pages/Title";
import { HeaderParameters } from "../components/pages/HeaderParameters";
import { Modal } from "../components/Modal";
import { useModal } from "../hooks/useModal";
import { useVistas } from "../hooks/useVistas";
import { usePersonas } from "../hooks/usePersonas";
import { notify } from "../utils/utils";
export const Personas = () => {
  const { tiposDocumentos,
    especialidades,
    horarios,
    enfermedades,
    consultorios,
    listarTiposDocumentos,
    listarEspecialidades,
    listarHorarios,
    listarEnfermedades,
    listarConsultorios, } = useVistas();

  const { persona,
      setPersona,
      personas,
      setPersonas,
      listarPersonas,
      editarValorPersona,
      obtenerPersona,
      guardarPersona} = usePersonas()
  

  useEffect(()=>{
    listarPersonas()
    listarTiposDocumentos()
    listarEspecialidades()
  },[])

  const [editando, setEditando] = useState(false);
  const [palabraBuscar, setPalabraBuscar] = useState("");
  const [isOpen, openModal, closeModal] = useModal(false);

  const GuardarNuevoRegistro =  async() => { 
    await guardarPersona()
    closeModal()
  }



  return (
    <div className="p-6">
      <Title title="Gestión de Personas" />

      <HeaderParameters
        openModal={() => openModal()}
        palabraBuscar={palabraBuscar}
        setPalabraBuscar={setPalabraBuscar}
        className="mt-4 mb-4"
      />

      <div className="max-w-5xl mx-auto px-4">
        <table className="w-full bg-white shadow rounded">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-4 text-left">Código</th>
              <th className="p-4 text-left">Nombres</th>
              <th className="p-4 text-left">Apellidos</th>
              <th className="p-4 text-right">Dirección</th>
              <th className="p-4 text-right">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {personas.length > 0 ? (
              personas.map((p) => (
                <tr key={p.CodPersona} className="border-b">
                  <td className="p-4">P0{p.Codigo}</td>
                  <td className="p-4">{p.Nombres}</td>
                  <td className="p-4">{p.ApellidoPaterno} {p.ApellidoMaterno}</td>
                  <td className="p-4">{p.Direccion}</td>
                  <td className="p-4 flex justify-end space-x-2">
                    <button
                      onClick={() => obtenerPersona(p)}
                      className="p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => notify("No se puede aliminar el registro","info")}
                      className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="p-4 text-center">
                  No se encontraron personas
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal
        isModalOpen={isOpen}
        closeModal={closeModal}
        title={editando ? "Editar Persona" : "Nueva Persona"}
        actionLabel={editando ? "Guardar Cambios" : "Registrar"}
        onAction={editando ? "actualizarPersona" :  GuardarNuevoRegistro}
      >
        <div className="grid grid-cols-3 gap-4">
         
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Nombres
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.Nombres}
              onChange={(e) => editarValorPersona("Nombres", e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Apellido Paterno
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.ApellidoPaterno}
              onChange={(e) =>
                editarValorPersona("ApellidoPaterno", e.target.value)
              }
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Apellido Materno
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.ApellidoMaterno}
              onChange={(e) =>
                editarValorPersona("ApellidoMaterno", e.target.value)
              }
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Fecha de Nacimiento
            </label>
            <input
              type="date"
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.FechaNacimiento}
              onChange={(e) =>
                editarValorPersona("FechaNacimiento", e.target.value)
              }
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Dirección
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.Direccion}
              onChange={(e) => editarValorPersona("Direccion", e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Género
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.Genero}
              onChange={(e) => editarValorPersona("Genero", e.target.value)}
            >
              <option value="" disabled>
                Selecciona el género
              </option>
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Celular
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.Celular}
              onChange={(e) => editarValorPersona("Celular", e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Tipo de Documento
            </label>
            <select
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.CodTipoDocumento}
              onChange={(e) =>
                editarValorPersona("CodTipoDocumento", e.target.value)
              }
            >
              <option value="" disabled>
                Seleccione
              </option>
              {tiposDocumentos.map((doc) => (
                <option key={doc.CodTipoDocumento} value={doc.CodTipoDocumento}>
                  {doc.AbreviacionTipoDocumento}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Número de Documento
            </label>
            <input
              className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={persona.NumeroDocumento}
              onChange={(e) =>
                editarValorPersona("NumeroDocumento", e.target.value)
              }
            />
          </div>

          {/* Campo para definir si es médico */}
          <div className="mb-4 col-span-2">
            <input
              type="checkbox"
              checked={persona.EsMedico}
              onChange={(e) =>
                editarValorPersona("EsMedico", e.target.checked)
              }
            />
            <label className="ml-2">¿Es Médico?</label>
          </div>
        </div>

        {persona.EsMedico && (
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Número de Colegiatura
              </label>
              <input
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={persona.NumeroColegiatura}
                onChange={(e) =>
                  editarValorPersona("NumeroColegiatura", e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Fecha de Ingreso
              </label>
              <input
                type="date"
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={persona.FechaIngreso}
                onChange={(e) =>
                  editarValorPersona("FechaIngreso", e.target.value)
                }
              />
            </div>
            <div className="mb-4 col-span-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Especialidad
              </label>
              <select
                className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={persona.CodEspecialidad}
                onChange={(e) =>
                  editarValorPersona("CodEspecialidad", e.target.value)
                }
              >
                <option value="" disabled>
                  Selecciona una especialidad
                </option>
                {especialidades.map((esp) => (
                  <option key={esp.CodEspecialidad} value={esp.CodEspecialidad}>
                    {esp.NombreEspecialidad}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
