import React, { useState } from 'react';
import { Header } from './Header'
import { Menu } from './Menu'
import '../../assets/css/layoutMaster.css'

export const LayoutMaster = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
  return (
    <div className={`dashboard ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
      <Header toggleMenu={toggleMenu} />
      <Menu />
      <main className="main">
        {children}
      </main>
    </div>
  );
};

