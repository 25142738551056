import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LayoutMaster } from './components/Layout/LayoutMaster'
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Personas } from './pages/Personas';
import { HistoriaClinica } from './pages/HistoriaClinica';
import { Citas } from './pages/Citas.js';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<LayoutMaster><Home/></LayoutMaster>} />
          <Route path="/personas" element={<LayoutMaster><Personas/></LayoutMaster>} />
          <Route path="/historiaclinica" element={<LayoutMaster><HistoriaClinica/></LayoutMaster>} />
          <Route path="/citas" element={<LayoutMaster><Citas/></LayoutMaster>} />
        </Routes>
    </Router>
  );
}

export default App;
